import React, { useContext, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import { CDN_BASE_URL } from "../config";

import { CartContext } from "../context/CartContext";
import { FaExclamationCircle, FaTrash } from "react-icons/fa";

import CheckoutPrice from "../components/CheckoutPrice";
import { Helmet } from "react-helmet";

const CartPage = () => {
  const [loading, setloading] = useState();
  const { cart, setCart } = useContext(CartContext);

  let sendFee = 50000;
  const itemCount = cart.reduce((accumulator, current) => accumulator + current.count, 0);
  let total = cart.reduce((accumulator, current) => {
    return accumulator + current.price * current.count;
  }, 0);
  
  // محاسبه درصد اضافه بر اساس تعداد آیتم‌ها
  if (itemCount > 0) {
    const percentageIncrease = 0.1;
  
    // افزودن درصد به مبلغ بر اساس تعداد آیتم‌ها
    for (let i = 1; i < itemCount; i++) {
      sendFee = sendFee + 5000;
    }
  }

  function handleDelete(e) {
    const newCart = cart.filter((c) => c.id !== e.id);

    setCart(newCart);
  }

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"آریانا پوشاک | سبد خرید"}</title>
        <meta name="og:title" content={"آریانا پوشاک | سبد خرید"} />
      </Helmet>
      <div className=" overflow-hidden  py-10  bg-white text-right min-h-[80vh]">
        <div class="grid px-[8px] sm:px-10 lg:grid-cols-3 lg:px-20 xl:px-32 gap-[1rem]">
          <div className="order-2 lg:order-1">
            <CheckoutPrice total={total + sendFee} sendFee={sendFee} />
          </div>

          <div class=" pt-8 lg:col-span-2 order-1 lg:order-2">
            <p class="text-xl font-medium">سفارشات</p>
            {cart?.length ? (
              cart.map((item) => (
                <div class="mt-6 space-y-3 rounded-lg border bg-white px-[8px] py-[16px] sm:px-6">
                  <div class="flex flex-col rounded-lg bg-white sm:flex-row">
                    <span className="flex flex-col items-center justify-center">
                      <Link>
                        <img
                          class="m-2 h-24 w-28 rounded-md border object-cover object-center"
                          src={CDN_BASE_URL + item?.img}
                          alt=""
                        />
                      </Link>
                    </span>

                    <div class="flex w-full flex-col px-4 py-4 items-end">
                      <span className="mb-3">
                        <FaTrash
                          onClick={() => {
                            handleDelete(item);
                          }}
                          color="red"
                          className="cursor-pointer"
                        />
                      </span>
                      <span class="font-semibold">{item?.name}</span>
                      <p class="text-lg font-bold">{item.color}</p>
                      <p class="text-lg font-bold">{item.size}</p>
                      <p class="text-lg font-bold">
                        {item.price}
                        تومان
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="w-full h-full text-center  text-[24px] lg:text-[32px] font-bold py-6">
                  سبد خرید شما خالی است
                </div>
              </>
            )}
          </div>
          <p
            dir="rtl"
            className="col-span-full order-last flex items-center gap-2 text-[14px] lg:text-[16px] mt-[1rem]"
          >
            <FaExclamationCircle className="min-w-[20px]" />
            مشتری عزیز موجودی کالا ها محدود می باشد . افزودن محصولات در سبد خرید
            به معنای رزرو برای شما نیست <br />
            برای حذف نشدن محصولات از سبد خرید , خرید خود را کامل کنید
          </p>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
