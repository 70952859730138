import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { httpRequest } from "../utils/httpRequest";

const Paginate = ({page,setPage,pageCount}) => {

  const itemsPerPage = 12;
  const [productCount,setproductCount] = useState(0)
  async function getAll() {
    const response = await httpRequest("GET","/product/count",{},{}).then((e)=>{
      setproductCount(e?.data?.data)
    })
  }
 useEffect(()=>{
  getAll()
 },[])

  
  function handlePagechange(e) {
    console.log(e)
    setPage(e.selected);
    
  }



  return (
    <ReactPaginate
      previousLabel={"<<"}
      nextLabel={">>"}
      pageCount={Math.ceil(productCount / itemsPerPage)}
      onPageChange={handlePagechange}
      pageRangeDisplayed={0}
      containerClassName={"paginationbttn"}
      disabledClassName={"disabledbttn"}
      previousLinkClassName={"previousbttn"}
      nextLinkClassName={"nextbttn"}
      activeClassName={"activebttn"}
      forcePage={page}
    />
  );
};

export default Paginate;
