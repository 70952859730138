import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { httpRequest } from "../utils/httpRequest";
import Loading from "./Loading";
import Paginate from "./Paginate";
import ProductCard from "./ProductCard";

const NewestProduct = ({ name }) => {
  const [page, setPage] = useState(0);
  
  const [products, setProducts] = useState([]);
  const [loading , setLoading] = useState(true)

  async function getProducts() {
    setLoading(true)
    if (name) {
      const response = await httpRequest(
        "GET",
        `/product?page=${page+1}&limit=12&category=${name}`,
        {},
        {}
      ).then((e) => {
        console.log(e);
        if (e.status === 200) {
          setProducts(e?.data?.data);
         
        }
      });
    } else {
      const response = await httpRequest(
        "GET",
        `/product?page=${page+1}&limit=12`,
        {},
        {}
      ).then((e) => {
        console.log(e);
        if (e.status === 200) {
          setProducts(e?.data?.data);
          
        }
      });
    }
    setLoading(false)
  }
  useEffect(() => {
    getProducts();
  }, [page, name]);
  if (loading) {
    return <Loading h={"100px"} />;
  }
  return (
    <div className="grid lg:grid-cols-4 grid-cols-2  rounded   mb-10 ">
      {products.map((e) => (
        <ProductCard data={e} />
      ))}
      <div className="col-span-full w-full">
        <Paginate page={page} products={products} setPage={setPage}  />
      </div>
    </div>
  );
};

export default NewestProduct;
